<!-- 选择优惠券流水 -->
<template>
  <div>
    <el-dialog
      :visible="openDialog"
      title="选择优惠券"
      :before-close="close"
      v-dialogDrag="true"
      width="1100px"
    >
      <!-- 整个表格 -->
      <div style="margin: 20px" v-loading="loading">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          class="tableClass"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
          border
          height="450"
          ref="multipleTable"
        >
          <!-- <el-table-column align="center" type="selection" width="55">
          </el-table-column> -->
          <el-table-column align="center" type="index" width="80" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="couponCaseNo" label="编号">
          </el-table-column>
          <el-table-column prop="couponCaseName" label="名称" align="center">
          </el-table-column>
          <el-table-column
            align="center"
            prop="begGlideNo"
            label="开始券号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="endGlideNo"
            label="结束券号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="usableQty"
            align="center"
            label="剩余张数"
            show-overflow-tooltip
            width="155"
          >
          </el-table-column>
          <el-table-column align="center" prop="unitQty" label="数量">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.unitQty"
                type="text"
                size="mini"
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                placeholder="数量"
                @input="handleInputUnitQty($event, scope)"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="mini">取 消</el-button>
        <el-button type="primary" @click="getConfirm" size="mini">{{
          confirmText
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAvailableMarketingCouponBillSale } from "@/api/marketing/coupon/sale";
import { getAvailableMarketingCouponBillReceive } from "@/api/marketing/coupon/receive";
export default {
  props: {
    //确定按钮
    confirmText: {
      type: String,
      default: "确 定",
    },
    //信息开关
    openDialog: {
      type: Boolean,
      default: false,
    },
    //重置搜索条件
    refreshSearch: {
      type: Object,
      default: function () {
        return {
          pageNum: 1,
          pageSize: 15,
        };
      },
    },
    contentType: {
      type: String,
      default: "sale",
    },
  },
  data() {
    return {
      loading: false, //加载
      total: 0, //条数
      tableData: [], //会员等级
      selectAllData: [], //选中数组
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
    };
  },

  watch: {
    openDialog(val) {
      if (val) {
        this.reset();
        this.getList();
      }
    },
  },
  methods: {
    handleInputUnitQty(e, scope) {
      if (!e) {
        return;
      }
      if (e > Number(scope.row.usableQty)) {
        this.$message.warning("所输数量不能大于剩余张数!");
        this.$set(this.tableData[scope.$index], "unitQty", "");
      }
    },
    //点击选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //确定按钮
    getConfirm() {
      const check = this.tableData.filter((item) => item.unitQty > 0) || [];
      if (check.length == 0) {
        this.$message.warning("请输入数量！");
        return;
      }
      // if (check.length >= 1) {
      //   let isComplete = true;
      //   check.forEach((item) => {
      //     if (!item.unitQty) {
      //       isComplete = false;
      //     }
      //   });
      //   if (!isComplete) {
      //     this.$message.warning("请输入勾选数据中的数量！");
      //     return;
      //   }
      // }
      this.$emit("handleConfirmData", check);

      this.close();
    },
    //关闭对话框
    close() {
      this.$emit("update:openDialog", false);
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectAllData = selection;
    },
    /** 查询分页列表 */
    getList() {
      this.loading = true;
      let reqName = getAvailableMarketingCouponBillSale;
      if (this.contentType == "receive") {
        reqName = getAvailableMarketingCouponBillReceive;
      }
      reqName(this.queryParams).then((response) => {
        this.tableData = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    //清空搜索框
    reset() {
      //清空查询参数
      this.queryParams = JSON.parse(JSON.stringify(this.refreshSearch));
    },
  },
};
</script>

<style lang="scss" scoped>
//输入框
.vipInput {
  width: 89%;
}

.searchInput {
  width: 68%;
  margin-right: 2%;
}

//整体盒子
.tableBody {
  padding: 30px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

//输入框高度
::v-deep .el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}

//处理默认对话框的样式
::v-deep .el-dialog__body {
  padding: 0;
}

.tableClass {
  width: 100%;
  border-top: 1px solid rgb(223, 230, 236) !important;
  border-left: 1px solid rgb(223, 230, 236) !important;
}
</style>
